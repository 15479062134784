import React from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import ContactSection from "./pages/ContactSection";
import Home from "./pages/Home";
import DynamicHeader from "./components/DynamicHeader";
import FloatingContactButton from "./components/FloatingContactButton";
import ScrollToTopButton from "./components/ScrollToTopButton";
import Frp from "./pages/Frp";
import Concrete from "./pages/Concrete";
import MultipurposeFields from "./pages/MultipurposeFields";
import FootballTurfs from "./pages/FootballTurfs";
import BasketballCourts from "./pages/BasketballCourts";
import TennisCourts from "./pages/TennisCourts";
import Products from "./pages/Products";
import AboutUs from "./pages/AboutUs";
import "animate.css";
import OurFountains from "./pages/OurFountains";
import Cricket from "./pages/Cricket";

function App() {
  return (
    <>
      {/* <Navbar /> */}
      <DynamicHeader />
      <div className="flex flex-col min-h-screen">
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/products" element={<Products />} />
            <Route path="/contact-us" element={<ContactSection />} />
            <Route path="/frp-pools" element={<Frp />} />
            <Route path="/concrete-pools" element={<Concrete />} />
            <Route path="/our-fountains" element={<OurFountains />} />
            <Route
              path="/services/multipurpose-fields"
              element={<MultipurposeFields />}
            />
            <Route
              path="/services/football-turfs"
              element={<FootballTurfs />}
            />
            <Route
              path="/services/basketball-courts"
              element={<BasketballCourts />}
            />
            <Route path="/services/tennis-courts" element={<TennisCourts />} />
            <Route path="/services/cricket" element={<Cricket />} />
          </Routes>
        </main>
      </div>
      <FloatingContactButton />
      <ScrollToTopButton />
      <Footer />
    </>
  );
}

export default App;
