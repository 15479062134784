import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AnimatedImageGrid from "../components/AnimatedImageGrid";
import "../styles/animation.css";
import FRPImage1 from "../utils/stair-pool.jpg";
import FRPImage2 from "../utils/frp-two.jpeg";
import FRPImage3 from "../utils/frp.jpeg";

export default function FRPPools() {
  const images = [
    { src: FRPImage1, alt: "FRP Pool 1" },
    { src: FRPImage2, alt: "FRP Pool 2" },
    { src: FRPImage3, alt: "FRP Pool 3" },
  ];
  // const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // setIsVisible(true);
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-blue-900 text-white p-4 sm:p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl sm:text-5xl font-bold mb-8 text-center animate__animated animate__fadeInDown">
          FRP Pools: The Future of Swimming
        </h1>

        <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-6 sm:p-8 mb-8 animate__animated animate__fadeInUp">
          <h2 className="text-2xl sm:text-3xl font-semibold mb-4">
            About FRP Pools
          </h2>
          <p className="mb-4">
            Fiber Reinforced Plastic (FRP) pools represent the cutting edge of
            pool technology. At ANANDA POOLS AND SPORTS SOLUTIONS, we've been at
            the forefront of this revolution, providing top-quality FRP pools.
          </p>
          <p>
            Our expertise in FRP pool construction and our commitment to
            excellence have made us a trusted name in the industry. We not only
            construct these state-of-the-art pools but also offer a wide range
            of FRP pool parts and products.
          </p>
        </div>

        <AnimatedImageGrid images={images} />

        <div className="grid md:grid-cols-2 gap-4 sm:gap-8 mb-8 mt-8">
          <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-4 sm:p-6 animate__animated animate__fadeInLeft">
            <h3 className="text-xl sm:text-2xl font-semibold mb-4">
              Benefits of FRP Pools
            </h3>
            <ul className="list-disc list-inside">
              <li>Rapid installation</li>
              <li>Low maintenance requirements</li>
              <li>Excellent chemical resistance</li>
              <li>Durability and long lifespan</li>
              <li>Sleek, modern designs</li>
            </ul>
          </div>
          <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-4 sm:p-6 animate__animated animate__fadeInRight">
            <h3 className="text-xl sm:text-2xl font-semibold mb-4">
              Our FRP Pool Services
            </h3>
            <ul className="list-disc list-inside">
              <li>Custom FRP pool design</li>
              <li>Professional installation</li>
              <li>FRP pool maintenance</li>
              <li>FRP pool parts and accessories</li>
              <li>Renovation of existing pools to FRP</li>
            </ul>
          </div>
        </div>

        <div className="text-center">
          <Link
            to="/contact-us"
            className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 animate-pulse"
          >
            Enquire
          </Link>
        </div>
      </div>
    </div>
  );
}
