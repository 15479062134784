import React, { useState, useEffect } from "react";
import "../styles/imagegrid.css";

const AnimatedImageGrid = ({ images = [] }) => {
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    const gridElement = document.querySelector(".animated-image-grid");
    if (gridElement) observer.observe(gridElement);

    return () => {
      if (gridElement) observer.unobserve(gridElement);
    };
  }, []);

  if (images.length === 0) {
    return null;
  }

  return (
    <div
      className={`animated-image-grid grid grid-cols-2 md:grid-cols-3 gap-4 ${
        inView ? "in-view" : ""
      }`}
    >
      {images.map((image, index) => (
        <div
          key={index}
          className={`image-container relative overflow-hidden rounded-lg shadow-lg transform transition-all duration-500 ease-in-out hover:scale-105 hover:shadow-xl`}
          style={{ animationDelay: `${index * 0.1}s` }}
        >
          <img
            src={image.src}
            alt={image.alt}
            className="w-full h-full object-cover transition-transform duration-500 ease-in-out hover:scale-110"
          />
        </div>
      ))}
    </div>
  );
};

export default AnimatedImageGrid;
