import React, { useState } from "react";
import { Phone, Mail } from "lucide-react";

const FloatingContactButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-white text-blue-600 p-4 rounded-full shadow-lg hover:bg-gray-300 transition-all duration-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        aria-label="Contact options"
      >
        <Phone className="w-6 h-6" />
      </button>
      {isOpen && (
        <div className="absolute bottom-16 right-0 bg-white rounded-lg shadow-xl p-4 space-y-2 animate-fade-in-up">
          <a
            href="tel:+919131959638"
            className="flex items-center space-x-2 text-gray-700 hover:text-blue-600 transition-colors duration-300"
          >
            <Phone className="w-5 h-5" />
            <span>Call us</span>
          </a>
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&to=anandapoolsandsports@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-2 text-gray-700 hover:text-blue-600 transition-colors duration-300"
          >
            <Mail className="w-5 h-5" />
            <span>Email us</span>
          </a>
        </div>
      )}
    </div>
  );
};

export default FloatingContactButton;
