import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/ProductsTeaser.css";

const ProductsTeaser = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <section className={`products-teaser ${isVisible ? "visible" : ""}`}>
      <div className="water-background">
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="bubble"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              width: `${Math.random() * 30 + 10}px`,
              height: `${Math.random() * 30 + 10}px`,
              animationDelay: `${Math.random() * 10}s`,
            }}
          ></div>
        ))}
      </div>
      <div className="content-wrapper">
        <h2 className="heading">
          <span className="word">HAVE</span>
          <span className="word">A</span>
          <span className="word">LOOK</span>
          <span className="word">AT</span>
          <span className="word">THE</span>
          <span className="word">POOL</span>
          <span className="word">PARTS</span>
          <span className="word">AND</span>
          <span className="word">PRODUCTS</span>
          <span className="word">WE SELL!</span>
        </h2>
        <Link to="/products" className="products-button">
          <span className="button-text">Our Products</span>
          <div className="button-particles">
            {[...Array(20)].map((_, i) => (
              <div key={i} className="particle"></div>
            ))}
          </div>
        </Link>
      </div>
    </section>
  );
};

export default ProductsTeaser;
