import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import AnimatedImageGrid from "../components/AnimatedImageGrid";
import "../styles/animation.css";

export default function BasketballCourts() {
  // const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-blue-900 text-white p-4 sm:p-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl sm:text-5xl font-bold mb-8 text-center animate__animated animate__fadeInDown">
          Basketball Courts: Where Legends Are Made
        </h1>

        <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-6 sm:p-8 mb-8 animate__animated animate__fadeInUp">
          <h2 className="text-2xl sm:text-3xl font-semibold mb-4">
            About Basketball Courts
          </h2>
          <p className="mb-4">
            Ananda's basketball courts are designed to elevate the game. Whether
            for professional arenas, school gymnasiums, or community centers,
            our courts provide the perfect stage for players to showcase their
            skills.
          </p>
          <p className="mb-4">
            With our expertise in court construction and surfacing, we deliver
            basketball facilities that offer superior traction, shock
            absorption, and ball response, ensuring players can perform at their
            best while minimizing the risk of injuries.
          </p>
          <p>
            Apart from installing high-quality turf, we also provide basketball
            products, including poles, rings, and nets. Our comprehensive
            services ensure that your court is game-ready and equipped with the
            essentials.
          </p>
        </div>

        {/* <AnimatedImageGrid images={images} /> */}

        <div className="grid md:grid-cols-2 gap-4 sm:gap-8 my-8">
          <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-4 sm:p-6 animate__animated animate__fadeInLeft">
            <h3 className="text-xl sm:text-2xl font-semibold mb-4">
              Benefits of Our Basketball Courts
            </h3>
            <ul className="list-disc list-inside">
              <li>High-performance playing surfaces</li>
              <li>Customizable court designs and colors</li>
              <li>Improved player safety with shock-absorbing systems</li>
              <li>Durable and low-maintenance</li>
              <li>Suitable for indoor and outdoor installations</li>
            </ul>
          </div>
          <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-4 sm:p-6 animate__animated animate__fadeInRight">
            <h3 className="text-xl sm:text-2xl font-semibold mb-4">
              Our Basketball Court Services
            </h3>
            <ul className="list-disc list-inside">
              <li>Professional court design and layout</li>
              <li>High-quality surface installation</li>
              <li>Precise line marking and graphics</li>
              <li>Lighting and scoreboard integration</li>
              <li>Regular maintenance and resurfacing</li>
              <li>Basketball pole installation and setup</li>
              <li>Provision of basketball rings, nets, and accessories</li>
            </ul>
          </div>
        </div>

        <div className="text-center">
          <Link
            to="/contact-us"
            className="inline-block bg-orange-600 hover:bg-orange-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 animate-pulse"
          >
            Enquire Now!
          </Link>
        </div>
      </div>
    </div>
  );
}
