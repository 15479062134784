import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import grating from "../utils/grating.jpg";
import heating from "../utils/heating_pump_part.jpg";
import jacuzzi from "../utils/jacuzzi.jpg";
import motor from "../utils/motor.jpg";
import ozonator from "../utils/ozonator.jpg";
import stair from "../utils/stair-pool.jpg";
import block from "../utils/starting_block_part.jpg";
import light from "../utils/underwater-light.jpg";
import endless from "../utils/endless_pool_machine_part.jpg";
import fountain from "../utils/fountain1.jpg";
import rope from "../utils/rope.jpg";
import timing from "../utils/timing.jpg";
import infinity from "../utils/infinity.jpg";

const products = [
  { id: 1, name: "Heating Pump", image: heating },
  {
    id: 2,
    name: "Endless Pool Machine",
    image: endless,
  },
  {
    id: 3,
    name: "Starting Block",
    image: block,
  },
  {
    id: 4,
    name: "Jacuzzi",
    image: jacuzzi,
  },
  {
    id: 5,
    name: "Filteration Motor",
    image: motor,
  },
  {
    id: 6,
    name: "Ozonator",
    image: ozonator,
  },
  {
    id: 7,
    name: "Fountains",
    image: fountain,
  },
  {
    id: 8,
    name: "Grating",
    image: grating,
  },
  {
    id: 9,
    name: "Stairs",
    image: stair,
  },
  {
    id: 10,
    name: "Underwater Lights",
    image: light,
  },
  {
    id: 11,
    name: "Rope",
    image: rope,
  },
  {
    id: 12,
    name: "Timing System",
    image: timing,
  },
  {
    id: 13,
    name: "Infinity Pool",
    image: infinity,
  },
];

const Products = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-blue-900 to-purple-900 text-white p-8">
      <h1 className="text-5xl font-extrabold text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500 animate-pulse">
        Our Products
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {products.map((product) => (
          <div
            key={product.id}
            className="relative overflow-hidden rounded-lg shadow-lg cursor-pointer transform transition-all duration-300 hover:scale-105"
            onClick={() => setSelectedProduct(product)}
          >
            <img
              src={product.image}
              alt={product.name}
              className="w-full h-64 object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300">
              <h3 className="text-2xl font-bold text-white">{product.name}</h3>
            </div>
          </div>
        ))}
      </div>

      {selectedProduct && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-75 transition-opacity duration-300"
          onClick={() => setSelectedProduct(null)}
        >
          <div
            className="bg-white rounded-lg p-8 max-w-3xl w-full text-black transform transition-all duration-300 scale-100 opacity-100"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={selectedProduct.image}
              alt={selectedProduct.name}
              className="w-full h-64 object-cover rounded-lg mb-4"
            />
            <h2 className="text-3xl font-bold mb-4">{selectedProduct.name}</h2>
            {/* <p className="mb-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p> */}
            <button
              className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors duration-300"
              onClick={() => setSelectedProduct(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <div className="text-center mt-16">
        <Link
          to="/contact-us"
          className="inline-block bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-4 px-8 rounded-full text-lg transition-all duration-300 transform hover:scale-110 hover:rotate-3 animate-bounce"
        >
          Enquire Now!
        </Link>
      </div>
    </div>
  );
};

export default Products;
