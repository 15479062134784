import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import pool1 from "../utils/pool3.jpg";
import pool2 from "../utils/pool2.jpg";
import pool3 from "../utils/pool5.jpg";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const pools = [
  {
    id: 1,
    name: "FRP POOL, HOTEL BHAGIRATHI HARIDWAR",
    image: pool1,
    status: "Ongoing",
    description: "A stunning FRP pool overlooking the city",
    location: [29.943766215803638, 78.16039482416902],
    progress: 70,
  },
  {
    id: 2,
    name: "RCC POOL, DDSA HYDERABAD",
    image: pool2,
    status: "Completed",
    description: "A serene RCC pool surrounded by lush landscaping",
    location: [17.41405511643885, 78.29662713970413],
    progress: 100,
  },
  {
    id: 3,
    name: "RCC POOL, BANGALORE",
    image: pool3,
    status: "Completed",
    description:
      "A sleek, geometric RCC pool design with cutting-edge features",
    location: [13.075328351509915, 77.51571872544153],
    progress: 100,
  },
];

const PoolShowcase = () => {
  const [activePool, setActivePool] = useState(pools[0]);
  const [isAnimating, setIsAnimating] = useState(false);
  const mapRef = useRef();

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.flyTo(activePool.location, 13, {
        duration: 2,
      });
    }
  }, [activePool]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePoolClick = (pool) => {
    if (pool.id !== activePool.id && !isAnimating) {
      setIsAnimating(true);
      setActivePool(pool);
      setTimeout(() => setIsAnimating(false), 500);
    }
  };

  return (
    <section className="bg-gradient-to-b from-blue-900 to-black py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl md:text-5xl font-bold text-center text-white mb-12">
          Some Of Our Stunning Projects
        </h2>
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="lg:w-2/3">
            <div className="relative overflow-hidden rounded-lg shadow-2xl">
              <img
                src={activePool.image}
                alt={activePool.name}
                className={`w-full h-[400px] object-cover transition-transform duration-500 ${
                  isAnimating ? "scale-110" : "scale-100"
                }`}
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-end p-6">
                <h3 className="text-3xl font-bold text-white mb-2">
                  {activePool.name}
                </h3>
                <p className="text-lg text-blue-200 mb-4">
                  {activePool.description}
                </p>
                <div className="flex items-center">
                  <span className="text-white mr-2">
                    Status: {activePool.status}
                  </span>
                  {activePool.status === "Ongoing" && (
                    <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 ml-2">
                      <div
                        className="bg-blue-600 h-2.5 rounded-full transition-all duration-500 ease-out"
                        style={{ width: `${activePool.progress}%` }}
                      ></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/3 space-y-4">
            {pools.map((pool) => (
              <div
                key={pool.id}
                className={`cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105 ${
                  activePool.id === pool.id
                    ? "bg-blue-600 text-white"
                    : "bg-white text-blue-900"
                } rounded-lg shadow-md overflow-hidden`}
                onClick={() => handlePoolClick(pool)}
              >
                <div className="p-4 flex items-center">
                  <img
                    src={pool.image}
                    alt={pool.name}
                    className="w-20 h-20 object-cover rounded-md mr-4"
                  />
                  <div>
                    <h4 className="font-bold text-lg">{pool.name}</h4>
                    <p
                      className={
                        activePool.id === pool.id
                          ? "text-blue-200"
                          : "text-blue-600"
                      }
                    >
                      {pool.status}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-12 h-[400px] rounded-lg overflow-hidden shadow-2xl">
          <MapContainer
            center={activePool.location}
            zoom={13}
            style={{ height: "100%", width: "100%" }}
            ref={mapRef}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {pools.map((pool) => (
              <Marker key={pool.id} position={pool.location}>
                <Popup>{pool.name}</Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
    </section>
  );
};

export default PoolShowcase;
