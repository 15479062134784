import React, { useEffect, useState } from "react";
import {
  Send,
  Loader2,
  Phone,
  Mail,
  MapPin,
  MessageCircle,
} from "lucide-react";
import AlertModal from "../components/AlertModal";

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    contactMethod: "email",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    console.log("Form submitted:", formData);
    if (formData.contactMethod === "whatsapp") {
      const message = `Name: ${formData.name}%0AEmail: ${formData.email}%0APhone: ${formData.phone}%0AMessage: ${formData.message}`;
      window.open(`https://wa.me/919131959638?text=${message}`, "_blank");
    } else {
      // Email method using Gmail
      const subject = encodeURIComponent("Contact Form Submission");
      const body = encodeURIComponent(`
        Name: ${formData.name}
        Email: ${formData.email}
        Phone: ${formData.phone}
        Message: ${formData.message}
      `);
      window.open(
        `https://mail.google.com/mail/?view=cm&fs=1&to=Dhamisports@gmail.com&su=${subject}&body=${body}`,
        "_blank"
      );
    }
    setIsSubmitting(false);
    setFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
      contactMethod: "email",
    });
    setIsAlertOpen(true);
  };

  const closeAlert = () => {
    setIsAlertOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="relative py-20 overflow-hidden bg-gradient-to-br from-gray-900 via-black to-gray-900">
      <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSA2MCAwIEwgMCAwIDAgNjAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzMzMzMzMyIgc3Ryb2tlLXdpZHRoPSIxIi8+PC9wYXR0ZXJuPjwvZGVmcz48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyaWQpIi8+PC9zdmc+')] opacity-30 animate-pulse"></div>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="text-center mb-20">
          <h2 className="text-5xl md:text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-purple-400 mb-4 animate-pulse">
            Contact Us
          </h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            We'd love to hear from you! Reach out to us for any inquiries or
            support.
          </p>
        </div>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
            <form
              onSubmit={handleSubmit}
              className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl p-8 shadow-2xl transform hover:scale-105 transition-all duration-300 relative overflow-hidden"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-blue-400 to-purple-500 opacity-30 animate-pulse"></div>
              <div className="relative z-10 space-y-6">
                <div className="group">
                  <label
                    htmlFor="name"
                    className="block text-gray-300 text-sm font-semibold mb-2"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-3 rounded-lg bg-gray-800 bg-opacity-50 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all duration-300 transform group-hover:translate-x-2 shadow-lg shadow-blue-500/50"
                    placeholder="Your Name"
                  />
                </div>
                <div className="group">
                  <label
                    htmlFor="email"
                    className="block text-gray-300 text-sm font-semibold mb-2"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-3 rounded-lg bg-gray-800 bg-opacity-50 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all duration-300 transform group-hover:translate-x-2 shadow-lg shadow-blue-500/50"
                    placeholder="your@email.com"
                  />
                </div>
                <div className="group">
                  <label
                    htmlFor="phone"
                    className="block text-gray-300 text-sm font-semibold mb-2"
                  >
                    Phone
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full px-4 py-3 rounded-lg bg-gray-800 bg-opacity-50 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all duration-300 transform group-hover:translate-x-2 shadow-lg shadow-blue-500/50"
                    placeholder="Your Phone Number"
                  />
                </div>
                <div className="group">
                  <label
                    htmlFor="message"
                    className="block text-gray-300 text-sm font-semibold mb-2"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    rows="4"
                    className="w-full px-4 py-3 rounded-lg bg-gray-800 bg-opacity-50 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all duration-300 transform group-hover:translate-x-2 resize-none shadow-lg shadow-blue-500/50"
                    placeholder="Your Message"
                  ></textarea>
                </div>
                <div>
                  <label className="block text-gray-300 text-sm font-semibold mb-2">
                    Preferred Contact Method
                  </label>
                  <div className="flex space-x-4">
                    <label className="inline-flex items-center group">
                      <input
                        type="radio"
                        name="contactMethod"
                        value="email"
                        checked={formData.contactMethod === "email"}
                        onChange={handleChange}
                        className="form-radio text-blue-400 focus:ring-blue-400 focus:ring-offset-gray-800"
                      />
                      <Mail
                        className="ml-2 text-blue-400 group-hover:text-blue-300 transition-colors duration-300"
                        size={20}
                      />
                      <span className="ml-1 text-gray-300 group-hover:text-gray-200 transition-colors duration-300">
                        Email
                      </span>
                    </label>
                    <label className="inline-flex items-center group">
                      <input
                        type="radio"
                        name="contactMethod"
                        value="whatsapp"
                        checked={formData.contactMethod === "whatsapp"}
                        onChange={handleChange}
                        className="form-radio text-green-400 focus:ring-green-400 focus:ring-offset-gray-800"
                      />
                      <MessageCircle
                        className="ml-2 text-green-400 group-hover:text-green-300 transition-colors duration-300"
                        size={20}
                      />
                      <span className="ml-1 text-gray-300 group-hover:text-gray-200 transition-colors duration-300">
                        WhatsApp
                      </span>
                    </label>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-3 px-4 rounded-lg transition-all duration-300 transform hover:scale-105 hover:rotate-1 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 flex items-center justify-center shadow-lg shadow-purple-500/50 hover:shadow-xl hover:shadow-purple-500/70"
                >
                  {isSubmitting ? (
                    <Loader2 className="animate-spin mr-2" size={20} />
                  ) : (
                    <Send className="mr-2" size={20} />
                  )}
                  {isSubmitting ? "Sending..." : "Send Message"}
                </button>
              </div>
            </form>
          </div>
          <div className="w-full lg:w-1/2 px-4">
            <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-2xl p-8 shadow-2xl h-full flex flex-col justify-between transform hover:scale-105 transition-all duration-300 relative overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-tl from-blue-400 to-purple-500 opacity-30 animate-pulse"></div>
              <div className="relative z-10">
                <h3 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-purple-400 mb-6 animate-pulse">
                  Get in Touch
                </h3>
                <div className="space-y-6">
                  <div className="flex items-start group">
                    <MapPin
                      className="text-blue-400 mr-4 flex-shrink-0 group-hover:text-blue-300 transition-colors duration-300"
                      size={24}
                    />
                    <p className="text-gray-300 group-hover:text-gray-200 transition-colors duration-300">
                      67 YAMUNA NAGAR, Darpan Colony, Gwalior, Thatipur, Gird,
                      Gwalior - 474001, Madhya Pradesh, India.
                    </p>
                  </div>
                  <div className="flex items-center group">
                    <Phone
                      className="text-blue-400 mr-4 flex-shrink-0 group-hover:text-blue-300 transition-colors duration-300"
                      size={24}
                    />
                    <a
                      href="tel:+919131959638"
                      className="text-gray-300 hover:text-gray-200 transition-colors duration-300"
                    >
                      +91 91319 59638
                    </a>
                  </div>
                  <div className="flex items-center group">
                    <Mail
                      className="text-blue-400 mr-4 flex-shrink-0 group-hover:text-blue-300 transition-colors duration-300"
                      size={24}
                    />
                    <a
                      href="mailto:Dhamisports@gmail.com"
                      className="text-gray-300 hover:text-gray-200 transition-colors duration-300"
                    >
                      anandapoolsandsports@gmail.com
                    </a>
                  </div>
                  <div className="flex items-center group">
                    <MessageCircle
                      className="text-blue-400 mr-4 flex-shrink-0 group-hover:text-blue-300 transition-colors duration-300"
                      size={24}
                    />
                    <a
                      href="https://wa.me/9131959638"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-300 hover:text-gray-200 transition-colors duration-300"
                    >
                      Chat on WhatsApp
                    </a>
                  </div>
                </div>
              </div>
              <div className="mt-12 relative z-10">
                <h4 className="text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-purple-400 mb-4 animate-pulse">
                  Follow Us
                </h4>
                <div className="flex space-x-4">
                  {["facebook", "twitter", "instagram", "linkedin"].map(
                    (social) => (
                      <a
                        key={social}
                        href={`https://${social}.com`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-gradient-to-r from-blue-500 to-purple-600 p-3 rounded-full transition-all duration-300 transform hover:scale-110 hover:rotate-6 shadow-lg shadow-purple-500/50 hover:shadow-xl hover:shadow-purple-500/70"
                      >
                        <span className="sr-only">{social}</span>
                        <svg
                          className="h-6 w-6 text-white"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertModal
        isOpen={isAlertOpen}
        onClose={closeAlert}
        message="Thank you for your message! We will get back to you soon."
      />
    </section>
  );
};

export default ContactSection;
