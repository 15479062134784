import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../styles/FountainSection.css";

const FountainSection = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let animationFrameId;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const fountainParticles = [];
    const fountainCount = 3;
    const gravity = 0.1;

    class Particle {
      constructor(x, y, speedX, speedY, size, color) {
        this.x = x;
        this.y = y;
        this.speedX = speedX;
        this.speedY = speedY;
        this.size = size;
        this.color = color;
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;
        this.speedY += gravity;
        this.size *= 0.98;
      }

      draw() {
        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    function createFountain(x) {
      if (fountainParticles.length < 1000) {
        for (let i = 0; i < 5; i++) {
          const size = Math.random() * 3 + 1;
          const speedX = Math.random() * 2 - 1;
          const speedY = Math.random() * -7 - 5;
          const hue = Math.random() * 60 + 180;
          fountainParticles.push(
            new Particle(
              x,
              canvas.height,
              speedX,
              speedY,
              size,
              `hsl(${hue}, 100%, 50%)`
            )
          );
        }
      }
    }

    function animate() {
      ctx.fillStyle = "rgba(0, 0, 0, 0.1)";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < fountainCount; i++) {
        createFountain(((i + 1) * canvas.width) / (fountainCount + 1));
      }

      for (let i = 0; i < fountainParticles.length; i++) {
        fountainParticles[i].update();
        fountainParticles[i].draw();

        if (
          fountainParticles[i].size <= 0.2 ||
          fountainParticles[i].y > canvas.height
        ) {
          fountainParticles.splice(i, 1);
          i--;
        }
      }

      animationFrameId = requestAnimationFrame(animate);
    }

    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <section className="relative min-h-screen bg-gradient-to-b from-black to-blue-900 overflow-hidden py-24">
      <div className="container mx-auto px-4 relative z-10">
        <h2 className="text-4xl md:text-5xl font-bold text-center text-white mb-6 flex flex-wrap justify-center items-center">
          <span className="animate-color-change px-2">We</span>
          <span className="animate-color-change px-2 animation-delay-250">
            Also
          </span>
          <span className="animate-color-change px-2 animation-delay-500">
            Install
          </span>
          <span className="animate-color-change px-2 animation-delay-750">
            Fountains!
          </span>
        </h2>
        <p className="text-xl text-blue-200 text-center mb-12 max-w-3xl mx-auto">
          At Ananda Pools and Sports Solutions, we specialize in installing
          stunning fountains for both residential and commercial spaces. Our
          expert team creates beautiful water features that transform your
          outdoor areas into serene oases or impressive focal points.
        </p>
      </div>
      <canvas
        ref={canvasRef}
        className="absolute inset-0 w-full h-full z-0"
      ></canvas>
      <div className="container mx-auto px-4 relative z-10">
        <Link to="/our-fountains" className="block w-max mx-auto">
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full transition-colors duration-300 transform hover:scale-105">
            Our Fountains
          </button>
        </Link>
      </div>
    </section>
  );
};

export default FountainSection;
