import React from "react";
import Header from "../components/Header";
import ImageSlider from "../components/ImageSlider";
import { Outlet } from "react-router-dom";
import PoolShowcase from "../components/PoolShowcase";
import OurWork from "../components/OurWork";
import OtherServices from "../components/OtherServices";
import AboutUs from "../pages/AboutUs";
import ProductsTeaser from "../components/ProductsTeaser";
import FountainSection from "../components/FountainSection";

const Home = () => {
  return (
    <>
      <Header />
      <ImageSlider />
      <PoolShowcase />
      <AboutUs />
      <OurWork />
      <FountainSection />
      <OtherServices />
      <ProductsTeaser />
      <Outlet />
    </>
  );
};

export default Home;
