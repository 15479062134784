import React, { useEffect, useRef, useState } from "react";
import "../styles/OurFountains.css";
import F1 from "../utils/fountain1.jpg";
import F2 from "../utils/fountain2.png";
import F3 from "../utils/fountain3.jpg";
import F4 from "../utils/fountain4.jpg";
import F5 from "../utils/fountain5.png";
import { Link } from "react-router-dom";

const OurFountains = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const imagesRef = useRef([]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    imagesRef.current.forEach((img) => observer.observe(img));

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fountainData = [
    {
      image: F1,
      title: "Brass And Dandelion",
      description:
        "A sleek, contemporary fountain with captivating LED lighting effects.",
    },
    {
      image: F2,
      title: "Garden Fountain",
      description:
        "A classic multi-tiered fountain perfect for formal gardens and grand entrances.",
    },
    {
      image: F3,
      title: "Bubble Water Fountain",
      description:
        "A rustic waterfall design that brings the serenity of nature to your space.",
    },
    {
      image: F4,
      title: "Dandelion Fountain",
      description:
        "An engaging fountain design that invites interaction and play.",
    },
    {
      image: F5,
      title: "Dandelion Fountain",
      description:
        "A sophisticated sphere fountain that adds a touch of modern art to any setting.",
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-blue-900 py-24 overflow-hidden">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl md:text-5xl font-bold text-center text-white mb-6 flex flex-wrap justify-center items-center">
          <span className="animate-color-change px-2">Our</span>
          <span className="animate-color-change px-2 animation-delay-330">
            Stunning
          </span>
          <span className="animate-color-change px-2 animation-delay-660">
            Fountains
          </span>
        </h1>
        <p className="text-xl text-blue-200 text-center mb-16 max-w-3xl mx-auto">
          Discover the magic of water with Ananda Pools and Sports Solutions.
          Our fountains bring life, movement, and tranquility to any space. From
          elegant residential water features to grand commercial installations,
          we create fountains that captivate and inspire.
        </p>
        <div className="space-y-32">
          {fountainData.map((fountain, index) => (
            <div
              key={index}
              className="fountain-item opacity-0 transform translate-y-12 transition-all duration-500 ease-out"
              ref={(el) => (imagesRef.current[index] = el)}
            >
              <div className="relative w-64 h-64 mx-auto overflow-hidden rounded-lg shadow-lg">
                <img
                  src={fountain.image}
                  alt={fountain.title}
                  className="absolute top-0 left-0 w-full h-full object-cover transition-transform duration-300 hover:scale-110"
                  style={{
                    transform: `translate(${
                      (mousePosition.x - window.innerWidth / 2) / 50
                    }px, ${(mousePosition.y - window.innerHeight / 2) / 50}px)`,
                  }}
                />
                <div className="fountain-overlay absolute inset-0 bg-black bg-opacity-70 p-4 flex flex-col justify-end transform translate-y-full transition-transform duration-300">
                  <h3 className="text-xl font-bold text-white mb-2">
                    {fountain.title}
                  </h3>
                  <p className="text-sm text-blue-200">
                    {fountain.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Link
          to="/contact-us"
          className="mt-16 bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full transition-colors duration-300 transform hover:scale-105 block mx-auto text-center"
        >
          Contact Us for Your Fountain Project
        </Link>
      </div>
    </div>
  );
};

export default OurFountains;
