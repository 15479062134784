import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AnimatedImageGrid from "../components/AnimatedImageGrid";
import "../styles/animation.css";
import ConcreteImage2 from "../utils/concrete-two.jpeg";
import ConcreteImage1 from "../utils/pool1.jpg";
import ConcreteImage3 from "../utils/pool2.jpg";

export default function ConcretePools() {
  const images = [
    { src: ConcreteImage1, alt: "Concrete Pool 1" },
    { src: ConcreteImage2, alt: "Concrete Pool 2" },
    { src: ConcreteImage3, alt: "Concrete Pool 3" },
  ];
  // const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // setIsVisible(true);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-blue-900 text-white p-4 sm:p-8">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl sm:text-5xl font-bold mb-8 text-center animate__animated animate__fadeInDown">
          Concrete Pools: Timeless Elegance
        </h1>

        <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-6 sm:p-8 mb-8 animate__animated animate__fadeInUp">
          <h2 className="text-3xl font-semibold mb-4">About Concrete Pools</h2>
          <p className="mb-4">
            Concrete pools offer unparalleled customization and durability. At
            ANANDA SPORTS SOLUTIONS, we've been crafting bespoke concrete pools.
          </p>
          <p>
            Our team of expert designers and builders ensures that each concrete
            pool we create is a masterpiece. We also provide a comprehensive
            range of concrete pool products and parts to keep your pool in
            pristine condition.
          </p>
        </div>

        <AnimatedImageGrid images={images} />

        <div className="grid md:grid-cols-2 gap-4 sm:gap-8 mb-8 mt-8">
          <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-4 sm:p-6 animate__animated animate__fadeInLeft">
            <h3 className="text-2xl font-semibold mb-4">
              Advantages of Concrete Pools
            </h3>
            <ul className="list-disc list-inside">
              <li>Unlimited design flexibility</li>
              <li>Exceptional durability</li>
              <li>Ability to create unique shapes and features</li>
              <li>Can be built to any size or depth</li>
              <li>Adds significant value to your property</li>
            </ul>
          </div>
          <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-4 sm:p-6 animate__animated animate__fadeInRight">
            <h3 className="text-2xl font-semibold mb-4">
              Our Concrete Pool Services
            </h3>
            <ul className="list-disc list-inside">
              <li>Custom concrete pool design</li>
              <li>Professional construction</li>
              <li>Concrete pool renovation</li>
              <li>Maintenance and repair</li>
              <li>Concrete pool accessories and equipment</li>
            </ul>
          </div>
        </div>

        <div className="text-center">
          <Link
            to="/contact-us"
            className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 animate-pulse"
          >
            Enquire
          </Link>
        </div>
      </div>
    </div>
  );
}
