import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, X } from "lucide-react";
import logo from "../utils/logo.jpg";

const DynamicHeader = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navItems = ["Home", "About Us", "Products", "Contact Us"];

  const NavItem = ({ label, mobile }) => {
    const baseClasses =
      "relative text-white rounded-md font-medium transition-all duration-300 ease-in-out overflow-hidden group";
    const mobileClasses = "block px-3 py-2 text-base w-full text-left";
    const desktopClasses = "inline-flex items-center px-3 py-2 text-sm";

    let href = `/#${label.toLowerCase().replace(/\s+/g, "-")}`;
    if (label === "About Us") {
      href = "/about-us";
    } else if (label === "Contact Us") {
      href = "/contact-us";
    } else if (label === "Home") {
      href = "/";
    } else if (label === "Products") {
      href = "/products";
    }

    return (
      <Link
        to={href}
        className={`${baseClasses} ${mobile ? mobileClasses : desktopClasses}`}
        onClick={() => mobile && setIsMenuOpen(false)}
      >
        <span className="relative z-10 transition-colors duration-300 group-hover:text-blue-600">
          {label}
        </span>
        <span className="absolute inset-0 bg-white transform origin-left scale-x-0 transition-transform duration-300 ease-out group-hover:scale-x-100"></span>
      </Link>
    );
  };

  return (
    <header
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled
          ? "bg-gradient-to-r from-[#011f4b] to-[#03396c] shadow-lg"
          : "bg-transparent"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <Link to="/" className="text-white">
              <img
                src={logo}
                alt="Ananda Pools And Sports"
                className="h-8 w-auto sm:h-10 md:h-12 lg:h-14"
              />
            </Link>
          </div>

          <nav className="hidden md:flex items-center space-x-4">
            {navItems.map((item) => (
              <NavItem key={item} label={item} />
            ))}
          </nav>

          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMenu}
              className="text-white hover:text-[#b3cde0] focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-expanded={isMenuOpen}
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <X className="h-6 w-6" aria-hidden="true" />
              ) : (
                <Menu className="h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`md:hidden ${isMenuOpen ? "block" : "hidden"}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-gradient-to-r from-[#011f4b] to-[#03396c]">
          {navItems.map((item) => (
            <NavItem key={item} label={item} mobile />
          ))}
        </div>
      </div>
    </header>
  );
};

export default DynamicHeader;
