import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/OurWork.css";
import FRP from "../utils/frp.jpeg";
import Concrete from "../utils/concrete.jpeg";
// import V from "../utils/video.mp4";
// import VW from "../utils/video.webm";

const poolTypes = [
  {
    type: "FRP Pools",
    image: FRP,
    description:
      "Fiber Reinforced Plastic pools offer quick installation, durability, and low maintenance. Perfect for residential and commercial applications with their sleek, modern design and excellent chemical resistance.",
    features: [
      "Quick Installation",
      "Low Maintenance",
      "Chemical Resistant",
      "Durable",
      "Modern Design",
    ],
  },
  {
    type: "Concrete Pools",
    image: Concrete,
    description:
      "Custom-designed concrete pools provide unlimited design flexibility and exceptional durability. Ideal for both residential and commercial projects requiring unique shapes and features.",
    features: [
      "Customizable Design",
      "Long-lasting",
      "Versatile",
      "Premium Finish",
      "Value Addition",
    ],
  },
];

const OurWork = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Error attempting to play video:", error);
      });
    }
  }, []);

  return (
    <section className="our-work">
      {/* <div className="bg-video">
        <video
          ref={videoRef}
          className="bg-video__content"
          autoPlay
          muted
          loop
          playsInline
          poster="/path/to/fallback-image.jpg"
        >
          <source src={V} type="video/mp4" />
          <source src={VW} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </div> */}
      <div className="container">
        <h2 className="section-title">
          <span className="title-word">Our</span>
          <span className="title-word">Exceptional</span>
          <span className="title-word">Work</span>
        </h2>
        <p className="section-subtitle">
          Dive into our world-class pool designs
        </p>

        <div className="card-container">
          {poolTypes.map((pool, index) => (
            <PoolCard key={pool.type} pool={pool} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

const PoolCard = ({ pool, index }) => {
  return (
    <div className="pool-card">
      <div className="card-inner">
        <div className="card-front">
          <div
            className="card-image"
            style={{ backgroundImage: `url(${pool.image})` }}
          >
            <div className="card-overlay">
              <h3 className="card-title">{pool.type}</h3>
              <div className="card-underline"></div>
            </div>
          </div>
        </div>
        <div className="card-back">
          <div className="card-content">
            <h3 className="card-title">{pool.type}</h3>
            <p className="card-description">{pool.description}</p>
            <ul className="feature-list">
              {pool.features.map((feature, index) => (
                <li key={index} className="feature-item">
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <Link
            to={`/${pool.type.toLowerCase().replace(" ", "-")}`}
            className="learn-more-btn"
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OurWork;
