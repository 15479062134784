import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import AnimatedImageGrid from "../components/AnimatedImageGrid";
import "../styles/animation.css";

export default function CricketPitches() {
  // const [isVisible, setIsVisible] = useState(false);

  // useEffect(() => {
  //   setIsVisible(true);
  //   window.scrollTo(0, 0);
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-blue-900 text-white p-4 sm:p-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl sm:text-5xl font-bold mb-8 text-center animate__animated animate__fadeInDown">
          Cricket Pitches: Perfecting the Game
        </h1>

        <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-6 sm:p-8 mb-8 animate__animated animate__fadeInUp">
          <h2 className="text-2xl sm:text-3xl font-semibold mb-4">
            About Our Cricket Pitches
          </h2>
          <p className="mb-4">
            Ananda's cricket pitch construction and training setups are designed
            to meet ICC standards. Our solutions offer unparalleled ball bounce,
            durability, and weather resistance for players of all skill levels.
          </p>
          <p>
            With cutting-edge technology and experienced craftsmanship, we
            create cricket pitches that ensure optimal playing conditions, along
            with advanced ball pitching machines for training and practice.
          </p>
        </div>

        {/* <AnimatedImageGrid images={images} /> */}

        <div className="grid md:grid-cols-2 gap-4 sm:gap-8 my-8">
          <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-4 sm:p-6 animate__animated animate__fadeInLeft">
            <h3 className="text-xl sm:text-2xl font-semibold mb-4">
              Benefits of Our Cricket Pitches
            </h3>
            <ul className="list-disc list-inside">
              <li>ICC-compliant pitch specifications</li>
              <li>Consistent ball bounce and performance</li>
              <li>All-weather durability</li>
              <li>Enhanced player safety</li>
              <li>Low maintenance requirements</li>
            </ul>
          </div>
          <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-4 sm:p-6 animate__animated animate__fadeInRight">
            <h3 className="text-xl sm:text-2xl font-semibold mb-4">
              Our Cricket Services
            </h3>
            <ul className="list-disc list-inside">
              <li>ICC-standard pitch construction</li>
              <li>Ball pitching machine installation</li>
              <li>Netting and practice area setups</li>
              <li>Customizable drainage systems</li>
              <li>Comprehensive pitch maintenance</li>
            </ul>
          </div>
        </div>

        <div className="text-center">
          <Link
            to="/contact-us"
            className="inline-block bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 animate-pulse"
          >
            Enquire Now!
          </Link>
        </div>
      </div>
    </div>
  );
}
