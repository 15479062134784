import React from "react";
import { X } from "lucide-react";

const AlertModal = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-sm w-full mx-4 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-400 to-purple-500 opacity-30 animate-pulse"></div>
        <div className="relative z-10">
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </button>
          <h2 className="text-2xl font-bold mb-4 text-blue-600">Success!</h2>
          <p className="text-gray-700">{message}</p>
          <button
            onClick={onClose}
            className="mt-6 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-bold py-2 px-4 rounded-lg transition-all duration-300 transform hover:scale-105 hover:rotate-1 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 shadow-lg shadow-purple-500/50 hover:shadow-xl hover:shadow-purple-500/70"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertModal;
