import React from "react";
import { Link } from "react-router-dom";
import { Facebook, Twitter, Youtube, Mail, MapPin, Phone } from "lucide-react";
import logo from "../utils/logo.jpg";

const Footer = () => {
  return (
    <footer className="relative bg-gradient-to-r from-[#011f4b] to-[#03396c] shadow-lg overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxwYXR0ZXJuIGlkPSJncmlkIiB3aWR0aD0iNTYiIGhlaWdodD0iMTAwIiBwYXR0ZXJuVW5pdHM9InVzZXJTcGFjZU9uVXNlIj48cGF0aCBkPSJNIDU2IDAgTCAwIDAgMCAxMDAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzk0YTNiOCIgc3Ryb2tlLXdpZHRoPSIxIi8+PC9wYXR0ZXJuPjwvZGVmcz48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyaWQpIi8+PC9zdmc+')] opacity-10 animate-pulse"></div>
        <div className="absolute inset-0 bg-gradient-to-t from-blue-900 to-transparent opacity-50"></div>
        <div className="absolute inset-x-0 top-0 h-px bg-gradient-to-r from-transparent via-blue-200 to-transparent"></div>
        <div className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-blue-200 to-transparent"></div>
      </div>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Logo and Company Info */}
          <div className="space-y-4 transform hover:scale-105 transition-transform duration-300">
            <Link to="/">
              <img
                src={logo}
                alt="Ananda Sports Solutions"
                className="h-16 w-auto sm:h-20 md:h-24 lg:h-28"
              />
            </Link>
            <p className="text-sm text-[rgb(179,205,224)]">
              At Ananda Pools and Sports Solutions, with decades of expertise in
              pool construction and sports facility development, we bring your
              aquatic dreams to life.
            </p>
            <div className="flex space-x-4">
              <SocialIcon Icon={Facebook} href="#" />
              <SocialIcon Icon={Twitter} href="#" />
              <SocialIcon Icon={Youtube} href="#" />
            </div>
          </div>

          {/* Quick Links */}
          <div className="transform hover:scale-105 transition-transform duration-300">
            <h3 className="text-lg font-semibold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-white">
              Quick Links
            </h3>
            <ul className="space-y-2">
              {[
                { name: "Home", path: "/" },
                { name: "About Us", path: "/about-us" },
                { name: "Our Services", path: "/#our-services" },
                { name: "Products", path: "/#products" },
                { name: "Contact Us", path: "/contact-us" },
              ].map((item) => (
                <li key={item.name}>
                  <FooterLink to={item.path}>{item.name}</FooterLink>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Information */}
          <div className="transform hover:scale-105 transition-transform duration-300">
            <h3 className="text-lg font-semibold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-200 to-white">
              Contact Us
            </h3>
            <ul className="space-y-2">
              <li className="flex items-start space-x-2">
                <MapPin className="w-5 h-5 mt-1 flex-shrink-0 text-blue-300" />
                <span className="text-blue-100">
                  67 YAMUNA NAGAR, Darpan Colony, Gwalior, Thatipur, Gird,
                  Gwalior - 474001, Madhya Pradesh, India.
                </span>
              </li>
              <li className="flex items-center space-x-2">
                <Mail className="w-5 h-5 flex-shrink-0 text-blue-300" />
                <FooterLink href="mailto:anandapoolsandsports@gmail.com">
                  anandapoolsandsports@gmail.com
                </FooterLink>
              </li>
              <li className="flex items-center space-x-2">
                <Phone className="w-5 h-5 flex-shrink-0 text-blue-300" />
                <FooterLink href="tel:91319 59638">+91 91319 59638</FooterLink>
              </li>
            </ul>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-8 pt-8 border-t border-blue-400 text-center text-sm text-blue-200">
          &copy; {new Date().getFullYear()} Ananda Pools and Sports Solutions.
          All rights reserved.
        </div>
      </div>
    </footer>
  );
};

const SocialIcon = ({ Icon, href }) => (
  <a
    href={href}
    className="bg-white bg-opacity-20 p-2 rounded-full hover:bg-opacity-30 transition-all duration-300 hover:scale-110 hover:rotate-6"
    aria-label={Icon.name}
  >
    <Icon className="w-5 h-5" />
  </a>
);

const FooterLink = ({ to, href, children }) => {
  const baseClasses =
    "relative text-blue-100 hover:text-white transition-colors duration-300 group";

  if (to) {
    return (
      <Link to={to} className={baseClasses}>
        <span className="relative z-10">{children}</span>
        <span className="absolute inset-x-0 bottom-0 h-0.5 bg-white transform origin-left scale-x-0 transition-transform duration-300 ease-out group-hover:scale-x-100"></span>
      </Link>
    );
  }

  return (
    <a href={href} className={baseClasses}>
      <span className="relative z-10">{children}</span>
      <span className="absolute inset-x-0 bottom-0 h-0.5 bg-white transform origin-left scale-x-0 transition-transform duration-300 ease-out group-hover:scale-x-100"></span>
    </a>
  );
};

export default Footer;
