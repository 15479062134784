import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/OtherServices.css";
import MultipurposeField from "../utils/multipurpose-field.jpeg";
import FootballTurf from "../utils/football-turf.jpeg";
import BasketballCourt from "../utils/basketball-court.jpeg";
import TennisCourt from "../utils/tennis-court.jpeg";
import Cricket from "../utils/Cricket.jpg";

const serviceTypes = [
  {
    type: "Multipurpose Fields",
    image: MultipurposeField,
    description: "",
    features: [
      "Multi-sport functionality",
      "Durable surfaces",
      "Customizable layouts",
      "Low maintenance",
      "All-weather usability",
    ],
  },
  {
    type: "Football Turfs",
    image: FootballTurf,
    description: "",
    features: [
      "FIFA-approved surfaces",
      "Shock-absorbing layers",
      "All-weather playability",
      "Minimal maintenance",
      "Long-lasting durability",
    ],
  },
  {
    type: "Basketball Courts",
    image: BasketballCourt,
    description: "",
    features: [
      "High-traction surfaces",
      "Shock-absorbing systems",
      "Precise line markings",
      "Customizable designs",
      "Indoor/outdoor options",
    ],
  },
  {
    type: "Tennis Courts",
    image: TennisCourt,
    description: "",
    features: [
      "ITF-certified surfaces",
      "Excellent ball bounce",
      "UV-resistant coatings",
      "Proper drainage systems",
      "Customizable colors",
    ],
  },
  {
    type: "Cricket",
    image: Cricket,
    description: "",
    features: [
      "ICC-compliant pitch",
      "Consistent ball bounce",
      "Weather-resistant turf",
      "Effective drainage systems",
      "Ball Pitching Machine And Net",
    ],
  },
];

const OtherServices = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className={`other-services ${isVisible ? "visible" : ""}`}>
      <div className="container">
        <h2 className="section-title">
          <span className="title-word">Our</span>
          <span className="title-word">Other</span>
          <span className="title-word">Services</span>
        </h2>
        <p className="subtitle">
          Explore our range of premium sports facilities
        </p>

        <div className="card-container">
          {serviceTypes.map((service, index) => (
            <ServiceCard key={service.type} service={service} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

const ServiceCard = ({ service, index }) => {
  return (
    <div className="service-card">
      <div className="card-inner">
        <div className="card-front">
          <div
            className="card-image"
            style={{ backgroundImage: `url(${service.image})` }}
          >
            <div className="card-overlay">
              <h3 className="card-title">{service.type}</h3>
              <div className="card-underline"></div>
            </div>
          </div>
        </div>
        <div className="card-back">
          <div className="card-content">
            <h3 className="card-title">{service.type}</h3>
            <p className="card-description">{service.description}</p>
            <ul className="feature-list">
              {service.features.map((feature, index) => (
                <li key={index} className="feature-item">
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <Link
            to={`/services/${service.type.toLowerCase().replace(" ", "-")}`}
            className="learn-more-btn"
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OtherServices;
