import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/AboutUs.css";

const aboutSections = [
  {
    title: "Our Vision",
    content:
      "At Ananada Sports, we envision a world where every backyard is an oasis, every pool a masterpiece. We're not just building pools; we're crafting experiences, memories, and lifestyles.",
    icon: "🌟",
  },
  {
    title: "Our Expertise",
    content:
      "With years of experience in pool design and construction, our team of experts brings unparalleled knowledge and skill to every project. From FRP to concrete, we master every material and technique.",
    icon: "🛠️",
  },
  {
    title: "Innovation",
    content:
      "We're at the forefront of pool technology, constantly pushing boundaries. Our smart pool solutions and eco-friendly designs are setting new standards in the industry.",
    icon: "💡",
  },
  {
    title: "Customer Satisfaction",
    content:
      "Your dream is our mission. We work closely with each client, ensuring that every detail is perfect. Our after-sales service ensures your pool remains a source of joy for years to come.",
    icon: "😊",
  },
];

const AboutUs = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={`about-us ${isVisible ? "visible" : ""}`}>
      <div className="container">
        <h2 className="title">About Ananada Pools And Sports Solutions</h2>

        <div className="content">
          <div className="info-box">
            <div className="icon">{aboutSections[activeIndex].icon}</div>
            <h3>{aboutSections[activeIndex].title}</h3>
            <p>{aboutSections[activeIndex].content}</p>
          </div>

          <div className="buttons">
            {aboutSections.map((section, index) => (
              <button
                key={section.title}
                className={index === activeIndex ? "active" : ""}
                onClick={() => setActiveIndex(index)}
              >
                {section.title}
              </button>
            ))}
          </div>
        </div>

        <div className="cta">
          <h3>Ready to dive in?</h3>
          <Link className="get-started" to="/contact-us">
            Get Started
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
