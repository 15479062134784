import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import AnimatedImageGrid from "../components/AnimatedImageGrid";
import "../styles/animation.css";

export default function TennisCourts() {
  // const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // setIsVisible(true);
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-blue-900 text-white p-4 sm:p-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl sm:text-5xl font-bold mb-8 text-center animate__animated animate__fadeInDown">
          Tennis Courts: Where Champions Are Made
        </h1>

        <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-6 sm:p-8 mb-8 animate__animated animate__fadeInUp">
          <h2 className="text-2xl sm:text-3xl font-semibold mb-4">
            About Our Tennis Courts
          </h2>
          <p className="mb-4">
            Ananda's tennis courts are designed to meet the highest standards of
            professional play while providing a comfortable environment for
            players of all levels. Our courts combine cutting-edge technology
            with expert craftsmanship to deliver an unparalleled playing
            experience.
          </p>
          <p>
            With years of experience in creating world-class tennis facilities,
            we've perfected the art of building courts that offer optimal ball
            bounce, reduced glare, and excellent drainage, ensuring year-round
            playability and performance.
          </p>
        </div>

        {/* <AnimatedImageGrid images={images} /> */}

        <div className="grid md:grid-cols-2 gap-4 sm:gap-8 my-8">
          <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-4 sm:p-6 animate__animated animate__fadeInLeft">
            <h3 className="text-xl sm:text-2xl font-semibold mb-4">
              Benefits of Our Tennis Courts
            </h3>
            <ul className="list-disc list-inside">
              <li>Professional-grade playing surface</li>
              <li>Excellent shock absorption and ball response</li>
              <li>UV-resistant and color-stable materials</li>
              <li>Low maintenance requirements</li>
              <li>Customizable court markings and colors</li>
            </ul>
          </div>
          <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-4 sm:p-6 animate__animated animate__fadeInRight">
            <h3 className="text-xl sm:text-2xl font-semibold mb-4">
              Our Tennis Court Services
            </h3>
            <ul className="list-disc list-inside">
              <li>Custom court design and construction</li>
              <li>Resurfacing and renovation of existing courts</li>
              <li>Lighting system installation</li>
              <li>Fencing and windscreen solutions</li>
              <li>Regular maintenance and repair services</li>
            </ul>
          </div>
        </div>

        <div className="text-center">
          <Link
            to="/contact-us"
            className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 animate-pulse"
          >
            Enquire Now!
          </Link>
        </div>
      </div>
    </div>
  );
}
