import React from "react";
import { Link } from "react-router-dom";
import {
  PocketIcon as Pool,
  Droplets,
  LifeBuoy,
  HardHat,
  Volleyball,
} from "lucide-react";

const Header = () => {
  return (
    <header className="relative bg-gradient-to-r from-blue-500 to-blue-700 text-white overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-blue-600 opacity-50"></div>
        <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1575429198097-0414ec08e8cd?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3')] bg-cover bg-center mix-blend-overlay"></div>
      </div>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-20 md:py-24 lg:py-32">
        <div className="text-center">
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold tracking-tight mb-2 sm:mb-4">
            Ananda Pools And Sports Solutions
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold mb-6 sm:mb-8">
            Dive into Excellence
          </p>
          <div className="flex flex-wrap justify-center gap-3 sm:gap-4 mb-6 sm:mb-8">
            <FeatureIcon Icon={Pool} text="Pool Design" />
            <FeatureIcon Icon={HardHat} text="Construction" />
            <FeatureIcon Icon={Droplets} text="Maintenance" />
            <FeatureIcon Icon={LifeBuoy} text="Equipment" />
            <FeatureIcon Icon={Volleyball} text="Sports Turfs" />
          </div>
          <Link
            to="/about-us"
            className="bg-white text-blue-600 font-bold py-2 px-6 sm:py-3 sm:px-8 rounded-full hover:bg-blue-100 transition duration-300 text-sm sm:text-base"
          >
            Learn More
          </Link>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 w-full overflow-hidden leading-[0]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          className="w-[calc(100%+1.3px)] h-[60px] sm:h-[80px] md:h-[100px] lg:h-[120px]"
          preserveAspectRatio="none"
        >
          <path
            fill="#ffffff"
            fillOpacity="1"
            d="M0,96L48,112C96,128,192,160,288,160C384,160,480,128,576,112C672,96,768,96,864,112C960,128,1056,160,1152,160C1248,160,1344,128,1392,112L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
    </header>
  );
};

const FeatureIcon = ({ Icon, text }) => (
  <div className="flex flex-col items-center">
    <div className="bg-white bg-opacity-20 p-2 sm:p-3 rounded-full mb-1 sm:mb-2">
      <Icon className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6" />
    </div>
    <span className="text-xs sm:text-sm font-medium">{text}</span>
  </div>
);

export default Header;
