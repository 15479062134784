import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import AnimatedImageGrid from "../components/AnimatedImageGrid";
import "../styles/animation.css";

export default function MultipurposeFields() {
  // const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // setIsVisible(true);
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-blue-900 text-white p-4 sm:p-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl sm:text-5xl font-bold mb-8 text-center animate__animated animate__fadeInDown">
          Multipurpose Fields: Versatility Redefined
        </h1>

        <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-6 sm:p-8 mb-8 animate__animated animate__fadeInUp">
          <h2 className="text-2xl sm:text-3xl font-semibold mb-4">
            About Multipurpose Fields
          </h2>
          <p className="mb-4">
            Ananda's multipurpose fields are the epitome of versatility in
            sports infrastructure. Designed to accommodate a wide range of
            activities, these fields are perfect for schools, community centers,
            and sports complexes looking to maximize the utility of their space.
          </p>
          <p>
            With years of experience in creating adaptable sporting venues,
            we've perfected the art of building fields that can transition
            seamlessly from one sport to another, ensuring year-round usage and
            optimal return on investment.
          </p>
        </div>

        {/* <AnimatedImageGrid images={images} /> */}

        <div className="grid md:grid-cols-2 gap-4 sm:gap-8 my-8">
          <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-4 sm:p-6 animate__animated animate__fadeInLeft">
            <h3 className="text-xl sm:text-2xl font-semibold mb-4">
              Benefits of Multipurpose Fields
            </h3>
            <ul className="list-disc list-inside">
              <li>Versatility for multiple sports and events</li>
              <li>Cost-effective space utilization</li>
              <li>Increased community engagement</li>
              <li>Year-round usability</li>
              <li>Customizable to specific needs</li>
            </ul>
          </div>
          <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-4 sm:p-6 animate__animated animate__fadeInRight">
            <h3 className="text-xl sm:text-2xl font-semibold mb-4">
              Our Multipurpose Field Services
            </h3>
            <ul className="list-disc list-inside">
              <li>Custom field design and planning</li>
              <li>State-of-the-art surface installation</li>
              <li>Multi-sport line marking</li>
              <li>Drainage system integration</li>
              <li>Ongoing maintenance and support</li>
            </ul>
          </div>
        </div>

        <div className="text-center">
          <Link
            to="/contact-us"
            className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 animate-pulse"
          >
            Enquire Now!
          </Link>
        </div>
      </div>
    </div>
  );
}
